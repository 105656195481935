@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://use.typekit.net/woi2bws.css");

body {
  /* font-family: "adobe-garamond-pro", serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lot{
  text-shadow: 0px 0px 0px white;
}

#BGimage{
  background-image: url("./images/Brairwood 34.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y:center;
  background-position-x: center;
}

/* code {
  font-family: "adobe-garamond-pro", serif;
} */
